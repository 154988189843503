import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import { RiYoutubeLine } from "react-icons/ri";

const SocialMedia = () => {
  return (
    <div className=" py-4">
      <div className="container mx-auto flex items-center px-[2.75rem] sm:px-[2.75rem]">
        <p className="text-black-900 mr-4">Follow us on:</p>
        <div className="flex space-x-4">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 transition duration-300 text-2xl md:text-3xl"
          >
            <FaFacebook/>
          </a>
          <a
            href="https://www.instagram.com/reachhospitals/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 transition duration-300 text-2xl md:text-3xl"
          >
            <FaInstagram />
          </a>
          <a
            href="https://youtube.com/@reachsuperspecialityhospital?si=NmvGDc0joTkKwZl5"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-500 transition duration-300 text-2xl md:text-3xl"
          >
            <RiYoutubeLine />
          </a>
          
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
