import React, { useState } from "react";
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import HeaderImageCarousel from '../../components/HeaderImageCarousel.jsx';

const services = {
  "Blood Tests": [
    {
      name: "CBP",
      fullForm: "Complete Blood Picture",
      purpose: "Assesses overall health and detects a wide range of disorders, including anemia, infection, and leukemia."
    },
    {
      name: "CRP",
      fullForm: "C-Reactive Protein",
      purpose: "Measures the level of CRP in your blood to detect inflammation or infection."
    },
    {
      name: "Fever Profile",
      fullForm: "Fever Profile",
      purpose: "A series of blood tests to diagnose causes of fever, including infections like malaria, dengue, or typhoid."
    },
    {
      name: "Dengue NS1, IgM, IgG",
      fullForm: "Dengue Serology",
      purpose: "Detects dengue virus antigens and antibodies to diagnose dengue fever."
    },
    {
      name: "Widal",
      fullForm: "Widal Test",
      purpose: "Used to diagnose enteric fever (typhoid fever) by detecting antibodies against Salmonella bacteria."
    },
    {
      name: "Typhoid DOT",
      fullForm: "Typhoid DOT Enzyme Immunoassay",
      purpose: "Detects antibodies related to typhoid fever for diagnostic purposes."
    },
    {
      name: "Malaria",
      fullForm: "Malaria Test",
      purpose: "Detects malaria infection through blood smear or rapid diagnostic test."
    },
    {
      name: "Blood Grouping & Typing",
      fullForm: "Blood Grouping and Rh Typing",
      purpose: "Determines your blood group (A, B, AB, O) and Rh factor (positive or negative)."
    },
    {
      name: "Vitamin D",
      fullForm: "Vitamin D Test",
      purpose: "Measures the level of vitamin D in your blood to assess bone health."
    },
    {
      name: "B12",
      fullForm: "Vitamin B12 Test",
      purpose: "Determines vitamin B12 levels, essential for nerve function and red blood cell production."
    },
    {
      name: "Folate Levels",
      fullForm: "Folate Test",
      purpose: "Measures folate levels, important for DNA synthesis and repair, and in pregnancy."
    },
    {
      name: "Mantoux Test",
      fullForm: "Tuberculosis (TB) Test",
      purpose: "Used to detect tuberculosis infection."
    },
    {
      name: "ESR",
      fullForm: "Erythrocyte Sedimentation Rate",
      purpose: "Measures inflammation in the body, often used to diagnose conditions like arthritis."
    },
    {
      name: "Serum Iron Studies",
      fullForm: "Serum Iron Test",
      purpose: "Measures iron levels in the blood to diagnose anemia or iron overload."
    },
    {
      name: "Iron Profile",
      fullForm: "Iron Profile",
      purpose: "Assesses iron levels, ferritin, and transferrin to diagnose iron deficiency or overload."
    }
  ],
  "Renal Profile": [
    {
      name: "Sr. Creatinine",
      fullForm: "Serum Creatinine",
      purpose: "Measures kidney function by assessing the level of creatinine in the blood."
    },
    {
      name: "Blood Urea",
      fullForm: "Blood Urea Nitrogen (BUN)",
      purpose: "Evaluates kidney function and can also provide insight into hydration status and liver health."
    },
    {
      name: "Sr. Sodium",
      fullForm: "Serum Sodium",
      purpose: "Assesses electrolyte balance and monitors conditions like dehydration or kidney disease."
    },
    {
      name: "Sr. Potassium",
      fullForm: "Serum Potassium",
      purpose: "Monitors electrolyte balance, heart function, and kidney health."
    },
    {
      name: "Sr. Chloride",
      fullForm: "Serum Chloride",
      purpose: "Helps maintain acid-base balance and overall body fluid status."
    },
    {
      name: "Sr. Calcium",
      fullForm: "Serum Calcium",
      purpose: "Measures calcium levels in the blood to evaluate bone health and detect parathyroid issues."
    },
    {
      name: "Sr. Magnesium",
      fullForm: "Serum Magnesium",
      purpose: "Important for muscle and nerve function, magnesium levels are checked for electrolyte balance."
    },
    {
      name: "Blood Urea Nitrogen",
      fullForm: "Blood Urea Nitrogen",
      purpose: "Measures urea in the blood to assess kidney function."
    }
  ],
  "Liver Tests": [
    {
      name: "Sr. Bilirubin",
      fullForm: "Serum Bilirubin",
      purpose: "Measures bilirubin levels in the blood to evaluate liver function and diagnose jaundice."
    },
    {
      name: "ALT",
      fullForm: "Alanine Transaminase",
      purpose: "An enzyme test that helps assess liver damage and diagnose liver conditions like hepatitis."
    },
    {
      name: "AST",
      fullForm: "Aspartate Transaminase",
      purpose: "Another liver enzyme test to evaluate liver damage and health."
    },
    {
      name: "GGT",
      fullForm: "Gamma-Glutamyl Transferase",
      purpose: "Helps detect liver disease and bile duct problems."
    },
    {
      name: "Lipid Profile",
      fullForm: "Lipid Profile",
      purpose: "Assesses cholesterol and triglycerides levels to evaluate heart disease risk."
    },
    {
      name: "Sr. Albumin",
      fullForm: "Serum Albumin",
      purpose: "Measures the main protein in the blood, helping evaluate liver and kidney function."
    }
  ],
  "Cardiac Profile": [
    {
      name: "Troponin Levels",
      fullForm: "Cardiac Troponin",
      purpose: "Detects heart muscle damage, commonly used to diagnose heart attacks."
    },
    {
      name: "Lipid Profile",
      fullForm: "Lipid Profile",
      purpose: "Assesses cholesterol and triglyceride levels to monitor heart health."
    },
    {
      name: "ECG",
      fullForm: "Electrocardiogram",
      purpose: "Records the electrical activity of the heart to detect heart problems."
    },
    {
      name: "2D Echo",
      fullForm: "2D Echocardiogram",
      purpose: "Uses ultrasound to create images of the heart, checking for structural abnormalities."
    },
    {
      name: "BNP",
      fullForm: "Brain Natriuretic Peptide",
      purpose: "Helps diagnose heart failure by measuring BNP levels in the blood."
    }
  ],
  "Endocrinology": [
    {
      name: "ANA Profile",
      fullForm: "Antinuclear Antibody Profile",
      purpose: "Used to diagnose autoimmune diseases such as lupus or rheumatoid arthritis."
    },
    {
      name: "Thyroid Profile",
      fullForm: "Thyroid Function Tests",
      purpose: "Measures levels of thyroid hormones to diagnose thyroid disorders."
    },
    {
      name: "Diabetic Profile",
      fullForm: "Diabetes Screening",
      purpose: "Assesses blood sugar and related markers to diagnose and manage diabetes."
    },
    {
      name: "RA Factor",
      fullForm: "Rheumatoid Factor",
      purpose: "Aids in diagnosing rheumatoid arthritis by detecting antibodies in the blood."
    },
    {
      name: "ASO Titre",
      fullForm: "Antistreptolysin O Titre",
      purpose: "Helps detect a past streptococcal infection and associated complications."
    },
    {
      name: "Amylase",
      fullForm: "Serum Amylase",
      purpose: "Measures amylase enzyme levels to detect pancreatic diseases."
    },
    {
      name: "Sr. Ferritin",
      fullForm: "Serum Ferritin",
      purpose: "Assesses iron stores in the body and helps diagnose iron deficiency or overload."
    },
    {
      name: "Lipase",
      fullForm: "Serum Lipase",
      purpose: "Measures the lipase enzyme to detect pancreatic diseases."
    },
    {
      name: "Sr. LDH",
      fullForm: "Serum Lactate Dehydrogenase",
      purpose: "Measures tissue damage in the body, often used to assess liver function or hemolysis."
    },
    {
      name: "ABG",
      fullForm: "Arterial Blood Gas",
      purpose: "Measures the oxygen and carbon dioxide levels in the blood, commonly used in critical care."
    },
    {
      name: "VBG",
      fullForm: "Venous Blood Gas",
      purpose: "Similar to ABG but used in less critical settings to assess acid-base balance."
    },
    {
      name: "Uric Acid",
      fullForm: "Serum Uric Acid",
      purpose: "Monitors uric acid levels to diagnose gout and monitor kidney function."
    },
    {
      name: "Hormonal Assay",
      fullForm: "Hormonal Test",
      purpose: "Measures hormone levels in the blood to diagnose endocrine disorders."
    }
  ],
  "Gynecology": [
    {
      name: "AMH",
      fullForm: "Anti-Müllerian Hormone",
      purpose: "Helps assess ovarian reserve and fertility potential."
    },
    {
      name: "FSH",
      fullForm: "Follicle Stimulating Hormone",
      purpose: "Measures FSH levels to evaluate reproductive health and fertility."
    },
    {
      name: "LH",
      fullForm: "Luteinizing Hormone",
      purpose: "Monitors ovulation and reproductive health."
    },
    {
      name: "GTT",
      fullForm: "Glucose Tolerance Test",
      purpose: "Assesses how well your body processes glucose, used in pregnancy to test for gestational diabetes."
    },
    {
      name: "Sr. Prolactin",
      fullForm: "Serum Prolactin",
      purpose: "Measures prolactin levels to diagnose reproductive and pituitary disorders."
    }
  ],
  "Radiology": [
    {
      name: "X-Ray",
      fullForm: "X-Ray Imaging",
      purpose: "Uses radiation to create images of the bones and internal structures for diagnosis."
    },
    {
      name: "USG",
      fullForm: "Ultrasound",
      purpose: "Uses sound waves to create images of organs and tissues, commonly used in pregnancy."
    },
    {
      name: "2D Echo",
      fullForm: "2D Echocardiogram",
      purpose: "Provides detailed images of the heart to check for structural problems."
    },
    {
      name: "CT",
      fullForm: "Computed Tomography",
      purpose: "Provides detailed cross-sectional images of the body for diagnosis of various conditions."
    },
    {
      name: "TVS",
      fullForm: "Transvaginal Sonography",
      purpose: "An ultrasound technique used to examine female reproductive organs."
    }
  ],
  "Cultures": [
    {
      name: "CSF Culture",
      fullForm: "Cerebrospinal Fluid Culture",
      purpose: "Used to diagnose infections in the brain and spinal cord."
    },
    {
      name: "Blood Culture",
      fullForm: "Blood Culture",
      purpose: "Detects infections that have spread to the bloodstream."
    },
    {
      name: "Urine Culture",
      fullForm: "Urine Culture",
      purpose: "Used to detect urinary tract infections."
    },
    {
      name: "Sputum Culture",
      fullForm: "Sputum Culture",
      purpose: "Helps diagnose respiratory infections, including pneumonia and tuberculosis."
    }
  ],
  "Screening": [
    {
      name: "HBsAg",
      fullForm: "Hepatitis B Surface Antigen",
      purpose: "Screens for Hepatitis B infection."
    },
    {
      name: "HIV",
      fullForm: "Human Immunodeficiency Virus",
      purpose: "Tests for the presence of HIV, the virus that causes AIDS."
    },
    {
      name: "HCV",
      fullForm: "Hepatitis C Virus",
      purpose: "Screens for Hepatitis C infection."
    }
  ]
};

const healthPackages = [
  {
    name: "Heart Health Package",
    description: "A comprehensive assessment focusing on heart health, including tests like ECG, echocardiogram, and cholesterol levels."
  },
  {
    name: "CT Angio Package",
    description: "A detailed examination of blood vessels using CT imaging, providing insights into potential blockages and cardiovascular health."
  },
  {
    name: "Master Health Check-up Female",
    description: "An extensive health check-up for females, covering various health markers including blood tests, gynecological tests, and more."
  },
  {
    name: "Master Health Check-up Male",
    description: "A thorough health check-up for males, encompassing various health assessments including blood tests and health evaluations."
  },
  {
    name: "Life Check-up Female",
    description: "A health package focused on women's health, including essential tests for early detection of potential health issues."
  },
  {
    name: "Life Check-up Male",
    description: "A tailored health check-up for men, designed to monitor key health indicators and promote proactive healthcare."
  },
  {
    name: "Basic Health Check-up Female",
    description: "A fundamental health assessment for females, including routine tests to ensure overall well-being."
  },
  {
    name: "Basic Health Check-up Male",
    description: "A basic health check-up for males, featuring essential tests to monitor general health and fitness."
  }
];


const Services = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const toggleExpand = (department) => {
    setExpandedCards((prev) => ({
      ...prev,
      [department]: !prev[department],
    }));
  };

  return (
    <>
      <Header />
      <HeaderImageCarousel />
      <div className="flex justify-center my-10 bg-gray-50 ">
        <div className="max-w-[1536px]">
          <div className="p-4 sm:p-8 min-h-screen">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Health Packages</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto">
              {healthPackages.map((packageItem, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 transition-transform duration-300 hover:scale-105 hover:shadow-xl hover:bg-reach-green hover:text-white cursor-pointer text-gray-700"
                >
                  <div className="p-6">
                    <h2 className="text-xl font-semibold mb-3">{packageItem.name}</h2>
                    <p className="leading-relaxed">{packageItem.description}</p>
                  </div>
                  <div className="bg-orange-100 p-3 text-center text-orange-600 font-bold text-sm">
                    {/* Example Offer or Report Time */}
                    5% Off | Reports in 24 hours
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 sm:p-8 md:pl-12 md:pr-12 ">
            <h1 className="text-3xl md:text-4xl font-bold text-center mb-12">Lab Services</h1>

            {/* Services Cards */}
            <div className="grid grid-cols-1 gap-8">
              {Object.keys(services).map((department, index) => (
                <div key={index} className="bg-white shadow-lg rounded-lg p-6 transition-all duration-300">
                  
                  {/* Discount badge */}
                  <div className="bg-orange-400 text-white text-sm rounded-full px-2 py-1 w-max mb-2">4% Off</div>

                  <h2 className="text-lg font-bold mb-4 text-gray-800">{department}</h2>

                  {/* Services List */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {services[department]
                      .slice(0, expandedCards[department] ? services[department].length : 4)
                      .map((test, testIndex) => (
                        <div
                          key={testIndex}
                          className="bg-gray-100 text-gray-700 p-4 rounded-lg shadow-md transition-all duration-300 md:mr-12 md:ml-12 md:mb-3 hover:bg-reach-green hover:text-white cursor-pointer"
                        >
                          <h3 className="text-md font-semibold  hover:text-white">{test.name}</h3>
                          <p className="text-sm">{test.purpose}</p>
                        </div>
                      ))}

                    {/* View more button */}
                    {services[department].length > 6 && (
                      <button
                        className="mt-4 text-reach-orange hover:underline"
                        onClick={() => toggleExpand(department)}
                      >
                        {expandedCards[department] ? "View Less" : "View More"}
                      </button>
                    )}
                  </div>

                  {/* Reports info */}
                  <div className="text-sm text-gray-500 mt-4">
                    Reports Within <span className="text-reach-orange font-semibold">24 hours</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
