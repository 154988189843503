import { useState } from 'react';
import Logo from '../../assets/Hospital_logo/Logo.png';
import { LuPhoneCall } from "react-icons/lu";
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white shadow-md w-full z-10">
      <div className="container mx-auto px-4 py-3 flex justify-between xl:items-center">

        {/* Logo for larger screens */}
        <div className="hidden xl:block text-2xl font-bold">
          <Link to="/"> {/* Link to homepage */}
              <img
                className="w-[186.122px] h-[48px] flex-shrink-0 cursor-pointer"
                src={Logo}
                alt="Logo"
              />
            </Link>
            <p className="text-reach-orange text-xs font-semibold ml-24 animate-color-wave">
              Healing lives
            </p>
        </div>

        {/* Links for larger screens */}
        <nav className="hidden lg:flex space-x-20 font-bold">
          <nav className='pt-4'>
            <Link to="/about" className="block text-gray-600 hover:text-gray-900">About</Link>
          </nav>

          {/* <a href="#services" className="pt-4 text-gray-600 hover:text-gray-900">Services</a> */}
          <nav className='pt-4'>
              <Link to="/labservices" className="block text-gray-600 hover:text-gray-900">Services</Link>
          </nav>
          <nav className='pt-4'>
            <Link to="/contact" className="text-gray-600 hover:text-gray-900">Contact</Link>
          </nav>
          {/* <a href="Contact" className="pt-4 text-gray-600 hover:text-gray-900">Contact</a> */}
          <button
            className="flex px-4 py-4 justify-center items-center gap-2 self-stretch text-white bg-[#06A89F] hover:bg-[#048f87] rounded"
          >
            Consult Online
          </button>
          <div className="relative flex items-center md:pr-3">
            <div 
                className="bg-reach-green text-white rounded-full flex items-center justify-center w-14 h-14 cursor-pointer"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <LuPhoneCall className="text-2xl" />
            </div>
            {showTooltip && (
                <div className="absolute right-full top-1/2 transform -translate-y-1/2 bg-red-600 border border-gray-300 rounded-md shadow-md p-2 ml-2 z-10">
                    <span className="text-white font-bold">Emergency</span>

                    <div className="absolute left-full top-1/2 transform -translate-y-1/2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-white ml-2"></div>
                </div>
            )}
        </div>
        </nav>

        {/* Hamburger menu for mobile screens */}
        <div className="lg:hidden flex justify-start">
          <button onClick={toggleSidebar} className="focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>

        {/* Logo for smaller screens */}
        <div className="flex flex-row items-center lg:hidden text-2xl font-bold">
          {/* Phone Icon with Call Functionality */}
          <a href="tel:+918885414108" className="flex items-center text-white text-lg font-bold mr-2">
              {/* Circular Icon */}
              <div className="bg-reach-green text-orange rounded-full p-2 flex items-center justify-center w-8 h-8">
                  <LuPhoneCall className="text-xl" />
              </div>
          </a>

          <Link to="/"> {/* Link to homepage */}
            <img
              className="w-[186.122px] h-[48px] flex-shrink-0 cursor-pointer"
              src={Logo}
              alt="Logo"
            />
          </Link>
        </div>
      </div>

      {/* Sidebar for mobile */}
      <div
        className={`inset-y-0 left-0 w-full bg-white shadow-md transition-all duration-700 ease-in-out lg:hidden overflow-hidden ${
    isOpen ? 'translate-y-0 max-h-screen' : '-translate-y-full max-h-0'
        } transition-transform duration-700 ease-in-out overflow-hidden lg:hidden`}
        style={{ height: isOpen ? 'auto' : '0' }} 
      >
        <div className="p-4">
          <nav className="space-y-4">
            <nav>
              <Link to="/about" className="block text-gray-600 hover:text-gray-900">About</Link>
            </nav>
            {/* <a href="#services" className="block text-gray-600 hover:text-gray-900">Services</a> */}
            <nav>
              <Link to="/labservices" className="block text-gray-600 hover:text-gray-900">Services</Link>
            </nav>
            <nav>
              <Link to="/contact" className="block text-gray-600 hover:text-gray-900">Contact</Link>
            </nav>
            <a href="#contactOnline"
              className=" block text-gray-600 hover:text-gray-900"
            >
              Consult Online
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
