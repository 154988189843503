import React, { useState, useEffect } from 'react';
import cardiologyImage from '../../assets/MedicalServices_images/Cardiology_images.jpeg';
import Neurology from '../../assets/MedicalServices_images/Neurology.png';
import Orthopedics from '../../assets/MedicalServices_images/Orthopedics.png';
import Urology from '../../assets/MedicalServices_images/Urology.png';
import Pulmanology from '../../assets/MedicalServices_images/Pulmanology.png';
import GeneralSurgery from '../../assets/MedicalServices_images/General Surgery.png';
import Gynecology from '../../assets/MedicalServices_images/Gynecology.png';
import Gastroenterology from '../../assets/MedicalServices_images/Gastroenterology.png';
import Pediatrics from '../../assets/MedicalServices_images/Pediatrics.jpg';
import GeneralMedicine from '../../assets/MedicalServices_images/GeneralMedicine.png';
import Neurosurgery from '../../assets/MedicalServices_images/NeuroSurgery.webp';
import CriticalCare from '../../assets/MedicalServices_images/CriticalCare.jpg';
const services = [
  { name: "Pediatrics & Neonatology", image: Pediatrics },
  { name: "General Surgery", image: GeneralSurgery },
  { name: "Pulmonology", image: Pulmanology },
  { name: "Orthopaedics", image: Orthopedics },
  { name: "Gynecology & Obstetrics", image: Gynecology },
  { name: "Cardiology", image: cardiologyImage },
  { name: "General Medicine", image: GeneralMedicine },
  { name: "Neurology", image: Neurology },
  { name: "Gastroenterology", image: Gastroenterology },
  { name: "Urology", image: Urology },
  {name: "Neurosurgery", image: Neurosurgery},
  {name: "Anaesthesia And Critical Care", image: CriticalCare},
];

const MedicalSpecialities = ({ name, image }) => (
  <div className="flex flex-col items-center p-4 border rounded-lg shadow-lg bg-white">
    <div className="w-16 h-16 mb-4">
      {typeof image === 'string' ? (
        <img src={image} alt={name} className="w-full h-full object-contain" />
      ) : (
        <div className="w-full h-full">{image}</div>
      )}
    </div>
    <h3 className="text-lg font-semibold text-center text-gray-700">{name}</h3>
  </div>
);

const ServiceGrid = () => {
  const [showAll, setShowAll] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    handleResize(); // Run once on mount
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const visibleServices = isLargeScreen || showAll ? services : services.slice(0, 4);

  return (
    <div className="container mx-auto pl-6 pr-6 md:pl-15 md:pr-15 pt-6 pb-6">
      <h2 className="text-3xl font-bold mb-8 text-center text-blue-900">Areas of Expertise and Specialities</h2>
      
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
        {visibleServices.map((service) => (
          <MedicalSpecialities key={service.name} name={service.name} image={service.image} />
        ))}
      </div>

      {!isLargeScreen && (
        <div className="text-center mt-6">
          <button 
            onClick={() => setShowAll(!showAll)} 
            className="bg-reach-green text-white px-4 py-2 rounded"
          >
            {showAll ? 'View Less' : 'View More'}
          </button>
        </div>
      )}
    </div>
  );
};

export default ServiceGrid;
