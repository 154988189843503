import React, { useState, useEffect, useRef } from 'react';

const Carousel = ({ children, interval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isHovered, setIsHovered] = useState(false); 
    const childrenArray = React.Children.toArray(children);
    const totalSlides = childrenArray.length;

    const transitionEndRef = useRef();

    const goToNext = () => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
    };

    const goToPrevious = () => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        if (!isHovered) { 
            const autoSlide = setInterval(goToNext, interval);
            return () => clearInterval(autoSlide);
        }
    }, [isHovered, currentIndex, interval]);

    const handleTransitionEnd = () => {
        if (currentIndex === totalSlides) {
            setIsTransitioning(false);
            setCurrentIndex(0);
        }
    };

    return (
        <div 
            className="relative w-full h-full overflow-hidden " //md:max-h-[400px] lg:max-h-[500px]"
            onMouseEnter={() => setIsHovered(true)} // Set hovered state on mouse enter
            onMouseLeave={() => setIsHovered(false)} // Reset hovered state on mouse leave
        >
            <div
                className={`flex transition-transform ${isTransitioning ? 'duration-500' : ''}`}
                style={{ transform: `translateX(-${(currentIndex % totalSlides) * 100}%)` }}
                onTransitionEnd={handleTransitionEnd}
                ref={transitionEndRef}
            >
                {childrenArray.map((child, index) => (
                    <div key={index} className="w-full flex-shrink-0">
                        {child}
                    </div>
                ))}

                {/* Clone of the First Slide */}
                <div className="w-full flex-shrink-0">
                    {childrenArray[0]}
                </div>
            </div>

            {/* Previous Button */}
            <button
                onClick={goToPrevious}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full hover:bg-gray-700"
            >
                &#10094;
            </button>

            {/* Next Button */}
            <button
                onClick={goToNext}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full hover:bg-gray-700"
            >
                &#10095;
            </button>

            {/* Dots Indicator */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {childrenArray.map((child, index) => (
                    <button
                        key={index}
                        className={`w-3 h-3 rounded-full ${index === (currentIndex % totalSlides) ? 'bg-gray-800' : 'bg-gray-400'}`}
                        onClick={() => {
                            setIsTransitioning(true);
                            setCurrentIndex(index);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Carousel;
