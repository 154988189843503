import hospitalImage from '../assets/InfoPane_images/Architecture.png';
import React, { useState, useEffect } from 'react';

const InfoPane = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [visibleChars, setVisibleChars] = useState(400);

    const text = `
    At Reach Super Speciality Hospital in Hyderabad, India, we are dedicated to delivering world-class healthcare 
    through the expertise and commitment of our highly skilled medical professionals. Our hospital offers a wide range 
    of specialized services, including advanced diagnostics, personalized treatment plans, and comprehensive recovery 
    programs. Our doctors work closely with multidisciplinary teams to ensure that every patient receives the highest 
    standard of care, tailored to their individual needs. We prioritize a patient-centered approach, combining clinical 
    excellence with compassion to create a supportive and healing environment.

    As a trusted healthcare provider, we are committed to making high-quality medical services accessible to all. Our 
    hospital is equipped with modern facilities that emphasize patient safety, comfort, and well-being. We focus not only 
    on immediate medical treatment but also on long-term health outcomes, providing holistic care that guides patients 
    through every step of their healthcare journey. At Reach Super Speciality Hospital, our mission is to deliver 
    exceptional healthcare with professionalism, empathy, and unwavering dedication to the community we serve.
  `;

    const toggleViewMore = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const updateVisibleChars = () => {
            if (window.innerWidth > 1000) {
                setVisibleChars(600); 
            } else {
                setVisibleChars(300); 
            }
        };

        updateVisibleChars();
        window.addEventListener('resize', updateVisibleChars);

        return () => {
            window.removeEventListener('resize', updateVisibleChars);
        };
    }, []);

    return (
        <div className="flex justify-center py-5">
            <div className="container max-w-6xl mx-auto px-6 pt-5 pb-5 mt-2 md:mt-5"> {/* max-w-6xl mx-auto px-4 pt-5 pb-5 mt-2 md:mt-5 */}
                <div className="flex flex-col md:flex-row md:space-x-8">
                    <div className="md:w-2/3 w-full flex flex-col pb-4 md:ml-20">
                        <h2 className="text-xl md:text-3xl font-bold mb-5 text-left text-reach-blue">About Our Hospital</h2>
                        <p className="text-sm md:text-lg text-justify">
                            {isExpanded ? text : `${text.substring(0, visibleChars)}`}
                            <button
                                onClick={toggleViewMore}
                                className="text-sm md:text-lg text-blue-500 hover:underline focus:outline-none font-medium"
                            >
                                &nbsp;{isExpanded ? 'read less...' : 'read more...'}
                            </button>
                        </p>
                    </div>
                    {/* Image Section */}
                    <div className="w-full h-[400px] md:h-[500px] items-center md:pl-10 md:pt-10 object-cover">
                        <img
                            src={hospitalImage}
                            alt="Hospital"
                            className="h-full w-auto rounded-lg shadow-2xl object-fit: cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoPane;
