import HeaderImageCarousel from "../../components/HeaderImageCarousel";
import Services from "../../components/Services/Services";
import InfoPane from "../../components/InfoPane";
import DoctorsPane from "../../components/DoctorsPane";
import CustomerReviews from "../../components/CustomerReview";
import SocialMedia from "../../components/SocialMedia";
import Header from "../../components/Header/Header";
import ServiceGrid from "../../components/Specialities/MedicalSpecialities";
import Ribbon from "../../components/Ribbon";
import Footer from "../../components/Footer/Footer";

const HomePage = () => {

    return (
        <div className={" bg-gray-200"}>
            {/* <Ribbon/> */}
            <Header/>
            <HeaderImageCarousel/>
            <div className="md:flex md:justify-center">
                <div className={"max-w-[1536px]"}>
                    <div><Services/></div>
                    {/* InfoPane */}
                    <div><InfoPane/></div>
                    <DoctorsPane/>
                    {/* CustomerReview */}
                    <div className="mt-5"><CustomerReviews/></div>
                    {/* MedicalSpecialities */}
                    <div><ServiceGrid /></div>
                    {/* SocialMedia*/}
                    <div><SocialMedia/></div>
                    <Footer/>
                </div>
            </div>
        </div>
    )
};

export default HomePage;