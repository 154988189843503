import React, { useState, useEffect } from 'react';
import Carousel from "./Carousel";

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 1024);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

function importAll(r) {
    return r.keys().map(r);
}

const webImages = importAll(require.context('../assets/Carousel_Images', false, /_web\.(jpe?g|png|webp)$/));
const mobImages = importAll(require.context('../assets/Carousel_Images', false, /_mob\.(jpe?g|png|webp)$/));

const HeaderImageCarousel = () => {
    const isMobile = useIsMobile();
    const images = isMobile ? mobImages : webImages;

    return (
        <Carousel>
            {images.map((image, index) => (
                <div key={index} className="relative">
                    <img
                        className="w-full h-full object-fit: cover"
                        src={image}
                        alt={`Slide ${index + 1}`}
                    />
                </div>
            ))}
        </Carousel>
    );
};

export default HeaderImageCarousel;
