
const ServiceCard = ({children, serviceName}) => {
    return (
        <div className={"w-36 h-36 lg:h-48 lg:w-48 bg-white hover:bg-reach-green text-reach-green hover:text-white hover:shadow-2xl flex flex-col gap-5 items-center justify-center cursor-pointer rounded-2xl"}>
            <div className={"w-16 h-16 lg:w-24 lg:h-24"}>
                {children}
            </div>
            <div className={"text-sm text-center font-bold "}>{serviceName}</div>
        </div>
    )
}

export default ServiceCard;