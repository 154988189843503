import React, { useState, useEffect } from 'react';
import Carousel from "./Carousel";

const CustomerReviews = () => {
  const reviews = [
    {
      text: "This hospital provided me with the best care I could have imagined! The doctors and staff were extremely professional and compassionate.",
      name: "Pavan Kumar Reddy",
      rating: 5,
    },
    {
      text: "Wonderful experience! The hospital is well-maintained, and the medical team was very supportive throughout my recovery.",
      name: "Abhijith Merugu",
      rating: 4,
    },
    {
      text: "The facilities and the medical team's attention to detail were amazing. I feel lucky to have been treated here.",
      name: "Chetan Ambati",
      rating: 5,
    },
    {
      text: "Excellent care! I highly recommend this hospital to anyone in need of specialized medical services.",
      name: "Rohan Gollena",
      rating: 5,
    },
    {
      text: "I had a great experience at this hospital. The staff was very helpful and supportive during my stay.",
      name: "Shubham Singh",
      rating: 4,
    },
    {
      text: "I had a great experience at this hospital. The staff was very helpful and supportive during my stay.",
      name: "Sravya Vallapureddy",
      rating: 4,
    },
    {
      text: "I had a great experience at this hospital. The staff was very helpful and supportive during my stay.",
      name: "Pavana Doddi",
      rating: 4,
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [reviewsPerSlide, setReviewsPerSlide] = useState(2);

  const toggleView = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <svg
        key={index}
        aria-hidden="true"
        className={`w-5 h-5 ${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M9.049 2.927C9.401 2.182 10.599 2.182 10.951 2.927l1.462 2.963 3.27.477c.711.104.996.98.481 1.484l-2.367 2.308.558 3.257c.121.711-.626 1.251-1.258.916L10 12.347l-2.929 1.54c-.632.335-1.379-.205-1.258-.916l.558-3.257L4.004 7.851c-.515-.504-.23-1.38.481-1.484l3.27-.477 1.462-2.963z" />
      </svg>
    ));
  };

  const renderReviewText = (text, index) => {
    const isExpanded = expandedIndex === index;
    const displayedText = isExpanded ? text : text.slice(0, 100); // Adjust character count as needed

    return (
      <div className="flex flex-col justify-center items-center pt-2 md:pt-4 text-sm md:text-md">
        <p className="font-normal">
          {displayedText}
          <button
            onClick={() => toggleView(index)}
            className="text-[#06A89F] hover:text-[#048a80] hover:underline font-semibold"
          >
            &nbsp;&nbsp;{isExpanded ? 'read less' : 'read more...'}
          </button>
        </p>
      </div>

    );
  };

  const groupedReviews = [];
  for (let i = 0; i < reviews.length; i += reviewsPerSlide) {
    groupedReviews.push(reviews.slice(i, i + reviewsPerSlide));
  }

  useEffect(() => {
    const updateReviewsPerSlide = () => {
      setReviewsPerSlide(window.innerWidth <= 640 ? 1 : 2);
    };

    updateReviewsPerSlide();

    window.addEventListener('resize', updateReviewsPerSlide);
    
    return () => {
      window.removeEventListener('resize', updateReviewsPerSlide);
    };
  }, []);

  return (
    <div className="bg-white p-5">
      <p className='text-reach-blue font-bold text-xl md:text-2xl mb-6 md:mb-4 text-center item-center'>Customer review</p>
      <Carousel>
        {groupedReviews.map((group, groupIndex) => (
          <div key={groupIndex} className="flex justify-center md:gap-x-24 md:p-10">
            {group.map((review, index) => (
              <React.Fragment key={index}>
                <div className=' flex '>
                  <div className ='flex flex-col justify-center items-center pl-10 pr-4 md:pr-1 md:pl-0 pb-6'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 80 80" fill="none" className=" w-14 h-14 md:w-18 md:h-18 mb-4">
                      <circle cx="40" cy="40" r="40" fill="#6C6C6C" />
                    </svg>
                  </div>
                  <div className="max-w-xs w-full pb-6 pr-10 md:pr-0 md:pl-8 pb-8">
                    <div className="flex items-center ">
                      <h5 className="text-md md:text-2xl font-bold">
                        {review.name}!
                      </h5>
                      <div className="flex pl-2">
                        {renderStars(review.rating)}
                      </div>
                    </div>
                    {renderReviewText(review.text, groupIndex * reviewsPerSlide + index)}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CustomerReviews;
 