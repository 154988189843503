import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import HeaderImageCarousel from '../../components/HeaderImageCarousel.jsx';
import mapImage from '../../assets/InfoPane_images/Location.png';
const ContactPage = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lvq0yzq', 'template_ffdmvx9', form.current, 'GIRdUrXu2rfFcvoVb')
      .then((result) => {
          console.log('Email successfully sent!', result.text);
          alert('Email sent successfully!');
      }, (error) => {
          console.log('Failed to send the email.', error.text);
          alert('Failed to send the email, please try again later.');
      });

    e.target.reset();
  };

  const mapsLink = "https://www.google.com/maps/place/REACH+HOSPITALS/@17.5407722,78.3778062,19.31z/data=!4m15!1m8!3m7!1s0x3bcb8dddcd990abf:0x3946d29cf1cdbbca!2sVilla-1,+SRINIDHI+HILL+PARK,+Whisper+Valley,+Hyderabad,+Telangana+500090,+India!3b1!8m2!3d17.5408448!4d78.3777565!16s%2Fg%2F11lpcz1x3j!3m5!1s0x3bcb8d003b67d3b5:0x71451d9f1b300df2!8m2!3d17.5405784!4d78.3776568!16s%2Fg%2F11w2_877bc?entry=ttu&g_ep=EgoyMDI0MTAxMy4wIKXMDSoASAFQAw%3D%3D";

  return (
    <>
      <Header />
      <HeaderImageCarousel />

      <div className="p-6 flex justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20 w-full max-w-5xl">

          <div>
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <p className="mb-4">
              At Reach Hospital, your health is our priority. Reach out to us for any inquiries, appointment bookings, or assistance. Our dedicated team is here to support you.
            </p>

            <div className="mb-4">
              <h3 className="font-bold">Phone</h3>
              <p className="text-reach-green hover:underline">+91 8885414108 | +91 8125777108</p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">Email</h3>
              <p className="text-reach-green hover:underline">reachsuperspeciality@gmail.com</p>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">Visit Us</h3>
              <p>Beside Srinidhi Hill Park, Opp. Dr. Reddy's Laboratory, Hill County Road, Bachupally, Hyderabad</p>
            </div>

            <div 
              className="w-full h-60 bg-cover bg-center cursor-pointer"
              style={{ backgroundImage: `url(${mapImage})` }} 
              onClick={() => window.open(mapsLink, '_blank')}
              aria-label="Open map location in Google Maps"
            ></div>
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-4">Get in Touch</h2>
            <p className="mb-4">Filling out this form ensures your inquiry reaches us quickly, allowing us to provide a faster and more tailored response.</p>

            <form ref={form} onSubmit={sendEmail} className="space-y-4" aria-labelledby="contact-form">
              <InputField label="Full Name"  htmlFor="to_name" id="name" type="text" name="user_name" placeholder="Enter your full name" required />
              <InputField label="Phone Number" id="phone" type="tel" name="user_phone" placeholder="Enter your phone number" required />
              <InputField label="Email" htmlFor="to_email" id="email" type="email" name="user_email" placeholder="Enter your email" required />
              
              <div>
                <label htmlFor="reason" className="block font-medium">Reason for Contacting Us</label>
                <select
                  id="reason"
                  name="contact_reason"
                  className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                  aria-label="Select reason for contacting us"
                >
                  <option>General Inquiry</option>
                  <option>Appointment Booking</option>
                  <option>Support</option>
                  <option>Other</option>
                </select>
              </div>

              <div>
                <label htmlFor="message" className="block font-medium">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                  rows="4"
                  placeholder="Enter your message"
                  aria-label="Enter your message"
                ></textarea>
              </div>

              <button
                type="submit"
                className="bg-reach-green text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Submit the contact form"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactPage;

// InputField Component
const InputField = ({ label, id, type, name, placeholder, required = false }) => {
  return (
    <div>
      <label htmlFor={id} className="block font-medium">{label}</label>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
        aria-label={label}
      />
    </div>
  );
};
