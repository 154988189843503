
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactPage from './pages/Contact/Contact';
import HomePage from "./pages/HomePage";
import Services from './pages/Services/LabServices.jsx';
import Index from "./pages/About";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
         <Route path="/about" element={<Index />} />
        <Route path="/labservices" element={<Services />} />
      </Routes>
    </Router>
  );
}

export default App;
