import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceCard from "./ServiceCard";
import Appointment from '../../assets/Services_Images/Appointment.jpg';
import Consultation from '../../assets/Services_Images/Consulting.png';
import labservices from '../../assets/Services_Images/Conical flask.png';
import medicalservices from '../../assets/Services_Images/Bag.png';
import vaccination from '../../assets/Services_Images/Vaccination.png';

const Services = () => {
    const [showAll, setShowAll] = useState(false);
    const navigate = useNavigate();

    const servicesData = [
        {
            name: "Book Appointment",
            icon: Appointment,
            isImage: true
        },
        {
            name: "Book Consultation",
            icon: Consultation,
            isImage: true
        },
        {
            name: "Lab Services",
            icon: labservices,
            isImage: true,
            link: "/labservices"
        },
        {
            name: "Medical Services",
            icon: medicalservices,
            isImage: true
        },
        {
            name: "Book Vaccination",
            icon: vaccination,
            isImage: true
        }
    ];

    const handleServiceClick = (service) => {
        if (service.link) {
            navigate(service.link);
        } else {
            console.log(`Clicked on ${service.name}`);
        }
    };

    const displayedServices = showAll ? servicesData : servicesData.slice(0, 4);

    return (
        <div className="flex justify-center py-5">
            <div className="w-full max-w-5xl mx-auto px-4">
                <div className="w-full flex justify-between pb-5">
                    <div className="text-xl md:text-2xl font-bold text-reach-blue">Services</div>
                    <div className="text-sm md:text-lg font-bold text-green-800 text-reach-orange md:pr-5">
                        <button onClick={() => setShowAll(!showAll)}>
                            {showAll ? 'View Less' : 'View All'}
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 justify-center">
                    {displayedServices.map(service => (
                        <button 
                            key={service.name} 
                            onClick={() => handleServiceClick(service)} 
                            className="w-full p-2 border-0 focus:outline-none"
                        >
                            <ServiceCard serviceName={service.name}>
                                {service.isImage 
                                    ? <img src={service.icon} alt={service.name} className="mx-auto" style={{ width: "90%", height: "90%" }} />
                                    : service.icon
                                }
                            </ServiceCard>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;
