import React from "react";
import SocialMedia from "../../components/SocialMedia";
import Logo from '../../assets/Hospital_logo/Logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-reach-green py-8">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-white">
        
        {/* Logo and Contact Section */}
        <div className="px-4 md:px-6">
          <Link to="/">
            <img
              className="w-[186.122px] h-[48px] cursor-pointer"
              src={Logo}
              alt="Logo"
            />
          </Link>
          <p className="text-sm font-bold ml-20 md:ml-24 animate-color-wave">
            Healing lives
          </p>
          <h4 className="relative text-lg text-reach-blue font-bold mb-3 ">
            Contact Us
          </h4>
          <ul>
            <li className="mb-2">Email: reachsuperspeciality@gmail.com</li>
            <li className="mb-2">Phone: 8885414108 | 8125777108</li>
            <li className="mb-2">Address: Beside Srinidhi Hill Park, Opp. Dr. Reddy's Laboratory, Hill County Road, Bachupally, Hyderabad</li>
          </ul>
        </div>

        {/* Services Section */}
        <div className="px-4 md:px-6">
          <h4 className="relative text-lg font-bold mb-3 text-reach-blue">
            Services offered
          </h4>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <li className="mb-2">Neonatology & Pediatrics</li>
            <li className="mb-2">General Medicine & Diabetology</li>
            <li className="mb-2">Gynecology & Obstetrics</li>
            <li className="mb-2">Anaesthesia & Critical Care</li>
            <li className="mb-2">General Surgery</li>
            <li className="mb-2">Neurology</li>
            <li className="mb-2">Pulmonology</li>
            <li className="mb-2">Cardiology</li>
            <li className="mb-2">Gastroenterology</li>
            <li className="mb-2">Orthopaedics</li>
            <li className="mb-2">Neurosurgery</li>
            <li className="mb-2">Urology</li>
          </ul>
        </div>

        {/* Booking Section */}
        <div className="px-4 md:px-6">
          <h4 className="relative text-lg text-reach-blue font-bold mb-3">
            Make an Appointment
          </h4>
          <p className="mb-6">
            Your health is our priority. Book your appointment today and trust
            our experienced team to provide the compassionate, expert care you
            deserve.
          </p>
          <button className="bg-white text-reach-green font-bold px-6 py-2 rounded mb-6">
            Book an Appointment
          </button>
          <div className="flex justify-start space-x-4">
            <SocialMedia />
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
