const DoctorCard = ({doctor}) => {
    return (
        <div className="py-4 px-4 lg:py-6 lg:px-10 w-[330px] h-[200px] md:w-[530px] md:h-[240px] lg:h-[260px] flex flex-col bg-white rounded-xl justify-between shadow-2xl">
            <div className={"flex w-full gap-2 lg:gap-5 items-center lg:items-start"}>
                <div className={"flex align-middle items-center rounded-full w-fit h-fit overflow-hidden relative pl-2"}>
                    <img
                        src={doctor.img}
                        alt={doctor.name}
                        className={"rounded-full m-auto w-[120px] h-[120px] scale-125 relative top-2.5"}
                    />
                </div>
                <div className={"flex-grow flex flex-col flex-1"}>
                    <div className={"text-md lg:text-2xl font-bold"}>{doctor.name}</div>
                    <div className={"font-light mb-3 text-sm"}>{doctor.title}</div>
                    <div className={"hidden md:inline text-sm lg:text-base "}>{doctor.qualifications}</div>
                    {/*<div className={""}>Speaks: {doctor.languages}</div>*/}
                    {/*<div className={"font-bold"}>{doctor.timings}</div>*/}
                </div>
            </div>
            <button className={"p-2 mt-3 md:w-full flex items-center justify-center rounded-xl bg-reach-green text-white font-bold"}>Consult Doctor</button>

        </div>
    )
}

export default DoctorCard;